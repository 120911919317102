<style scoped>
svg {
  cursor: pointer;
  margin: 15px;
}
</style>

<template>
  <div>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" @click="() => goto('https://instagram.com/divvyinvestimentos')">
      <path d="M18.4218 2.16724H7.58542C4.59304 2.16724 2.16724 4.59304 2.16724 7.58542V18.4218C2.16724 21.4142 4.59304 23.84 7.58542 23.84H18.4218C21.4142 23.84 23.84 21.4142 23.84 18.4218V7.58542C23.84 4.59304 21.4142 2.16724 18.4218 2.16724Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.338 12.3207C17.4718 13.2225 17.3177 14.1436 16.8978 14.9529C16.4779 15.7621 15.8135 16.4184 14.9991 16.8283C14.1848 17.2382 13.2619 17.3808 12.3617 17.236C11.4616 17.0911 10.6301 16.6662 9.98538 16.0215C9.3407 15.3768 8.91572 14.5453 8.77088 13.6451C8.62603 12.745 8.76871 11.8221 9.1786 11.0077C9.5885 10.1934 10.2447 9.52896 11.054 9.10906C11.8633 8.68915 12.7843 8.5351 13.6862 8.66884C14.6061 8.80525 15.4578 9.23391 16.1154 9.89151C16.773 10.5491 17.2016 11.4008 17.338 12.3207Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9636 7.0437H18.975" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <!-- <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" @click="() => goto('https://spotify.com')">
      <g clip-path="url(#clip0_1027:734)">
        <path d="M17.0325 16.1114C13.1226 14.3038 7.64969 15.1045 7.41955 15.1381C6.90415 15.2148 6.54936 15.6966 6.62847 16.212C6.70518 16.7274 7.18702 17.0822 7.70243 17.0031C7.75277 16.9959 12.8397 16.2504 16.2414 17.823C16.3684 17.8829 16.5051 17.9093 16.6369 17.9093C16.9941 17.9093 17.3345 17.7055 17.4927 17.3627C17.7133 16.8905 17.5071 16.3319 17.0325 16.1114Z" fill="#111317" />
        <path d="M18.1329 12.7168C13.6932 10.6648 7.46761 11.5733 7.20391 11.6141C6.68851 11.6908 6.33612 12.1726 6.41283 12.688C6.48954 13.2034 6.97138 13.5582 7.48439 13.4791C7.54432 13.4695 13.4199 12.6161 17.3418 14.4284C17.4689 14.4883 17.6055 14.5147 17.7374 14.5147C18.0945 14.5147 18.435 14.3109 18.5932 13.9681C18.8113 13.4959 18.6052 12.9349 18.1329 12.7168Z" fill="#111317" />
        <path d="M18.8711 9.169C13.9664 6.90123 7.07914 7.90806 6.78668 7.95121C6.27127 8.02792 5.91888 8.50976 5.99559 9.02517C6.0723 9.54057 6.55415 9.89536 7.06716 9.81625C7.13188 9.80666 13.6931 8.85257 18.0777 10.8806C18.2047 10.9406 18.3413 10.9669 18.4732 10.9669C18.8304 10.9669 19.1708 10.7632 19.329 10.4204C19.5496 9.9481 19.3434 9.38715 18.8711 9.169Z" fill="#111317" />
        <path d="M12.576 0.90332C6.09871 0.90332 0.82959 6.17244 0.82959 12.6498C0.82959 19.1271 6.09871 24.3962 12.576 24.3962C19.0534 24.3962 24.3225 19.1271 24.3225 12.6498C24.3225 6.17244 19.0534 0.90332 12.576 0.90332ZM12.576 22.5096C7.13911 22.5096 2.71621 18.0867 2.71621 12.6498C2.71621 7.21284 7.13911 2.78994 12.576 2.78994C18.013 2.78994 22.4359 7.21284 22.4359 12.6498C22.4359 18.0867 18.013 22.5096 12.576 22.5096Z" fill="#111317" />
      </g>
      <defs>
        <clipPath id="clip0_1027:734">
          <rect width="23.9723" height="23.9723" fill="white" transform="translate(0.589844 0.663574)" />
        </clipPath>
      </defs>
    </svg> -->

    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" @click="() => goto('https://www.facebook.com/profile.php?id=100071057259650')">
      <path d="M20.026 2.1665H16.775C15.3381 2.1665 13.9599 2.73735 12.9438 3.75345C11.9277 4.76956 11.3569 6.14769 11.3569 7.58468V10.8356H8.10596V15.1701H11.3569V23.8392H15.6914V15.1701H18.9423L20.026 10.8356H15.6914V7.58468C15.6914 7.29729 15.8056 7.02166 16.0088 6.81844C16.212 6.61522 16.4876 6.50105 16.775 6.50105H20.026V2.1665Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <!-- <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" @click="() => goto('https://linkedin.com')">
      <path d="M17.3382 8.66895C19.0626 8.66895 20.7164 9.35396 21.9357 10.5733C23.155 11.7926 23.8401 13.4464 23.8401 15.1708V22.7562H19.5055V15.1708C19.5055 14.596 19.2772 14.0447 18.8707 13.6383C18.4643 13.2318 17.913 13.0035 17.3382 13.0035C16.7634 13.0035 16.2122 13.2318 15.8057 13.6383C15.3993 14.0447 15.171 14.596 15.171 15.1708V22.7562H10.8364V15.1708C10.8364 13.4464 11.5214 11.7926 12.7408 10.5733C13.9601 9.35396 15.6139 8.66895 17.3382 8.66895V8.66895Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.50178 9.75293H2.16724V22.7566H6.50178V9.75293Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.33451 6.5019C5.53146 6.5019 6.50178 5.53158 6.50178 4.33463C6.50178 3.13768 5.53146 2.16736 4.33451 2.16736C3.13756 2.16736 2.16724 3.13768 2.16724 4.33463C2.16724 5.53158 3.13756 6.5019 4.33451 6.5019Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
    </svg> -->

    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" @click="() => goto('https://youtube.com/channel/UC7bejg3NgvWmMyUAOiArgxg')">
      <path d="M24.4254 6.95651C24.2967 6.44222 24.0345 5.97102 23.6654 5.59048C23.2963 5.20994 22.8333 4.93356 22.3231 4.78923C20.4593 4.33411 13.0039 4.33411 13.0039 4.33411C13.0039 4.33411 5.54845 4.33411 3.6846 4.83258C3.17447 4.9769 2.71147 5.25329 2.34235 5.63383C1.97323 6.01436 1.71107 6.48557 1.58234 6.99985C1.24123 8.8914 1.07437 10.8103 1.08387 12.7323C1.07171 14.6688 1.23858 16.6023 1.58234 18.5081C1.72426 19.0064 1.99229 19.4597 2.36055 19.8241C2.72881 20.1886 3.18485 20.4519 3.6846 20.5886C5.54845 21.0871 13.0039 21.0871 13.0039 21.0871C13.0039 21.0871 20.4593 21.0871 22.3231 20.5886C22.8333 20.4443 23.2963 20.1679 23.6654 19.7874C24.0345 19.4069 24.2967 18.9357 24.4254 18.4214C24.7639 16.5441 24.9307 14.6398 24.9239 12.7323C24.936 10.7958 24.7692 8.86229 24.4254 6.95651V6.95651Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5657 16.2762L16.7966 12.7327L10.5657 9.18921V16.2762Z" stroke="#111317" stroke-width="1.9977" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Social',
  methods: {
    goto(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
