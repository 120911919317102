<style scoped>
ul li {
  list-style: none;
  line-height: 2rem;
}

ul li a {
  color: #333;
}
</style>

<template>
  <div class="site-container">
    <b-row>
      <b-col sm="1">
        <logo-divvy />
      </b-col>

      <b-col sm="auto">
        <ul>
          <li><b>Sobre Nós</b></li>
          <li><a href="/about">Quem Somos</a></li>
        </ul>
      </b-col>

      <b-col sm="auto">
        <ul>
          <li><b>Oportunidades</b></li>
          <li><a href="/opportunity">Investimentos</a></li>
        </ul>
      </b-col>

      <b-col sm="auto">
        <ul>
          <li><b>Parceiros</b></li>
          <li><a href="/construtora">Construtora</a></li>
          <li><a href="/corretor">Corretor</a></li>
        </ul>
      </b-col>

      <b-col sm="auto">
        <ul>
          <li><b>Conteúdos</b></li>
          <li><a href="/blog">Divvy Blog</a></li>
        </ul>
        <ul>
          <li><b>Política e Termos</b></li>
          <li><a href="/privacy">Política de Privacidade</a></li>
          <li><a href="/terms">Termos de Uso</a></li>
        </ul>
      </b-col>

      <b-col sm="auto">
        <ul>
          <li><b>Ajuda</b></li>
          <li><a href="/faq">FAQ</a></li>
        </ul>
        <ul>
          <li><b>Fale Conosco</b></li>
          <li><a href="https://wa.me/554732373041" target="_blank">+55 47 3237-3041</a></li>
          <li><a href="mailto:contato@divvy.com.br">contato@divvy.com.br</a></li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import LogoDivvy from '../../LogoDivvy.vue'

export default {
  name: 'FooterLinks',
  components: {
    BRow,
    BCol,
    LogoDivvy,
  },
}
</script>
