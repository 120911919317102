<template>
  <div style="width: 100% !important; height: 1000% !important; position: fixed; z-index: 9998 !important; background: rgba(34, 41, 47, 0.5); left: 0; top: 0;">
    <div class="loading" style="position: fixed;">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
      <div class="text">
        {{text}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['text']
}
</script>

<style scoped>
body {
  margin: 0;
}

.text {
  width: 26em !important;
  margin-left: -12em !important;
  padding: 0.1em;
  margin-top: 3em;
  color: white;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
}

::shadow .loading .modal-dialog .modal-lg .modal-dialog-centered {
  position: fixed;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading .backgroung  {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:none;
}

.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #F8AC18;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #f8ad18;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
  @media (max-width: 1000px) {
    .text{
      width: 13em !important;
      text-align: center;
      margin-left: -300% !important;
    }
  }
</style>
