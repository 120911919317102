<template>
  <div class="d-card fadeIn" v-if="image" :style="[bg, { backgroundImage: `url(${image})` }]">
    <div class="d-card-content">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: ['image'],

  data() {
    return {
      bg: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    }
  },
}
</script>

<style scoped>
.d-card-content {
  background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 100%);
  background: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 20px;
}

.d-card {
  border-radius: 10px;
  margin-bottom: 30px;
}
@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1; }
}
@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fadeIn {
  -webkit-animation: fadeIn 3s ease-in-out;
  -moz-animation: fadeIn 3s ease-in-out;
  -o-animation: fadeIn 3s ease-in-out;
  animation: fadeIn 3s ease-in-out;
}
</style>
