<template>
  <card-fade :image="item.image" style="width: 100%">
    <div>
      <b-button pill size="sm" variant="warning" @click="$emit('filter', item.blogCategoryName)">{{ item.blogCategoryName }}</b-button>
    </div>

    <div :style="{ height: `${size == 'full' ? 250 : 100}px` }"></div>

    <p style="font-size: 1.6rem; font-weight: 600; color: white" @click="() => goto(item)">{{ item.title }}</p>

    <div>
      <b-button style="margin-top: 30px" pill variant="light" class="bg-transparent text-white btn-sm" @click="() => goto(item)">Saiba mais</b-button>
    </div>
  </card-fade>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import CardFade from '@/components/CardFade.vue'

export default {
  components: {
    BButton,
    CardFade,
  },

  props: ['item', 'size'],

  data() {
    return {
      bg: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    }
  },

  methods: {
    goto(item) {
      window.location.href = `/blog-view/${item.id}`
    },
  },
}
</script>
