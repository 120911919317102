<style scoped>
.blog-link {
  background: none;
  border: none;
}
</style>

<template>
  <div>
    <div class="site-container">
      <loader v-if="loading"></loader>

      <div style="background: #eee; border-radius: 6px; margin-bottom: 20px; text-align: center">
        <b-button variant="light" class="blog-link" v-for="(cat, i) in categs" :key="i" @click="loadItemsByCateg(cat.name)">{{ cat.name }}</b-button>
      </div>

      <b-row>
        <b-col sm="6">
          <blog-item-card v-if="items[0]" :item="items[0]" size="full" @filter="categ => loadItemsByCateg(categ)" />
          <blog-item-card v-if="items[1]" :item="items[1]" size="half" @filter="categ => loadItemsByCateg(categ)" />
        </b-col>
        <b-col sm="6">
          <blog-item-card v-if="items[2]" :item="items[2]" size="half" @filter="categ => loadItemsByCateg(categ)" />
          <blog-item-card v-if="items[3]" :item="items[3]" size="full" @filter="categ => loadItemsByCateg(categ)" />
        </b-col>
      </b-row>

      <div style="height: 1px; background: #eee" class="mb-4 mt-2">&nbsp;</div>

      <b-row>
        <b-col sm="6" v-for="(item, i) in itemsOthers" :key="i">
          <blog-item-card :item="item" size="half" @filter="categ => loadItemsByCateg(categ)" />
        </b-col>
      </b-row>
    </div>

    <div class="site-container">
      <newsletter-card />
      <Footer />
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'

import Footer from '../components/parts/footer/Footer.vue'
import NewsletterCard from '../components/NewsletterCard.vue'

import BlogService from '@/services/BlogService'
import BlogItemCard from '../components/blog/BlogItemCard.vue'

import Loader from '../../../components/Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Footer,
    BlogItemCard,
    NewsletterCard,
    Loader,
  },

  data() {
    return {
      items: [],
      itemsSemImage: [],
      categs: [],
      loading: false,
    }
  },

  computed: {
    itemsOthers() {
      return this.items.slice(4)
    },
  },

  methods: {
    async loadItems() {
      this.loading = true
      const { categ } = this.$route.params
      const filters = categ ? `BlogCategoryNameFilter=${categ}&MaxResultCount=1000` : '&MaxResultCount=1000'
      const data = (await BlogService.getItemListPublic(filters)).data.result
      this.itemsNotImage = data.items.map(p => ({ ...p.blogItem, blogCategoryName: p.blogCategoryName, blogStatusName: p.blogStatusName }))
      this.loadImages(data)
      this.loading = false
    },

    async loadImages(list) {
      const data = list
      data.items.forEach(async item => {
        const i = item
        const itemSemImg = this.itemsNotImage.filter(it => it.id === i.blogItem.id)[0]
        this.items.push(itemSemImg)
        const binary = (await BlogService.getBinary(i.blogItem.coverPhotoId)).data.result.appBinaryObjects
        itemSemImg.image = `data:${binary.dataType};base64,${binary.bytes}`
      })
    },

    async loadItemsByCateg(categName) {
      this.items = []
      this.loading = true
      const data = (await BlogService.getItemListPublic(`BlogCategoryNameFilter=${categName}`)).data.result
      this.itemsNotImage = data.items.map(p => ({ ...p.blogItem, blogCategoryName: p.blogCategoryName, blogStatusName: p.blogStatusName }))
      this.loadImages(data)
      this.loading = false
    },

    async loadCategs() {
      this.loading = true
      this.categs = (await BlogService.getCategoryList()).data.result.items.map(p => p.blogCategory)
      this.loading = false
    },
  },

  async mounted() {
    // workaround for render bug on internal route
    const path = window.location.href.split('/')[3]
    if (path !== 'blog') window.location.href = '/blog'

    await this.loadItems()
    await this.loadCategs()
  },
}
</script>
