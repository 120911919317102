<style scoped>
@media screen and (min-width: 601px) {
  .cnpj {
    width: 50vw;
    margin: 0 auto;
  }
}

.nowrap {
  text-align: center;
  white-space: nowrap;
}
</style>

<template>
  <div>
    <hr class="mt-5">
    <footer-links />
    <div class="site-container">
      <b-row style="border-top: 1px solid #eee; border-bottom: 1px solid #eee; padding: 10px 0">
        <b-col sm="8" class="v-align">
          <span style="font-size: 90%">Rua Buenos Aires, 459, sala 202, Ponta Aguda - Blumenau/SC - 89051-050</span>
        </b-col>
        <b-col sm="4" class="text-center">
          <social />
        </b-col>
      </b-row>
    </div>
    <div class="cnpj">
      <b-row class="text-center mt-3" style="color: #ccc">
        <b-col class="nowrap" sm="4">Divvy Investimentos Imobiliários LTDA</b-col>
        <b-col class="nowrap" sm="1">|</b-col>
        <b-col class="nowrap" sm="2"> CRECI-SC: 7664J</b-col>
        <b-col class="nowrap" sm="1">|</b-col>
        <b-col class="nowrap" sm="4"> CNPJ: 41.789.953/0001-68</b-col>
      </b-row>
    </div>
    <div class="text-center mt-3" style="color: #ccc">Copyright 2022 - Todos os direitos reservados</div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import FooterLinks from './FooterLinks.vue'
import Social from './Social.vue'

export default {
  name: 'Footer',
  components: {
    BRow,
    BCol,
    FooterLinks,
    Social,
  },
}
</script>
