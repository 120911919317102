<template>
  <svg width="90" height="40" viewBox="0 0 111 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.7517 22.0144C41.7517 17.2701 44.7216 14.4062 47.7783 14.4062C49.3864 14.4062 50.357 14.9993 51.3422 15.925L51.1828 13.7264V8.77966H55.4855V29.2609H51.9796L51.6754 27.829H51.5739C50.5454 28.8559 49.1111 29.6225 47.7059 29.6225C44.0841 29.6081 41.7517 26.7442 41.7517 22.0144ZM51.1828 24.7915V18.8323C50.415 18.1524 49.5602 17.921 48.7779 17.921C47.3872 17.921 46.1558 19.2083 46.1558 21.971C46.1558 24.8204 47.1409 26.1077 48.8069 26.1077C49.7341 26.0933 50.4729 25.7461 51.1828 24.7915Z" fill="#111317" />
    <path d="M65.8147 14.7534H70.1463L72.0297 21.595C72.4063 23.0414 72.783 24.5312 73.1597 26.05H73.2755C73.6522 24.5312 74.0289 23.0269 74.4055 21.595L76.2889 14.7534H80.4177L75.6804 29.261H70.7113L65.8147 14.7534Z" fill="#111317" />
    <path d="M81.1277 14.7534H85.4593L87.3426 21.595C87.7193 23.0414 88.0959 24.5312 88.4726 26.05H88.5885C88.9652 24.5312 89.3418 23.0269 89.7185 21.595L91.6018 14.7534H95.7306L90.9789 29.261H86.0098L81.1277 14.7534Z" fill="#111317" />
    <path d="M97.5414 34.6417L98.3092 31.3728C98.5845 31.4451 98.9757 31.5608 99.2944 31.5608C100.714 31.5608 101.467 30.7797 101.83 29.6515L102.047 28.8994L96.4404 14.7534H100.772L102.844 20.9875C103.264 22.3037 103.611 23.6778 103.988 25.0953H104.104C104.423 23.7212 104.756 22.3471 105.118 20.9875L106.886 14.7534H111.014L105.973 29.4201C104.597 33.0506 103.047 34.9309 99.6276 34.9309C98.7149 34.9309 98.1354 34.8152 97.5414 34.6417Z" fill="#111317" />
    <path d="M12.1835 13.7988C11.2709 14.71 11.2709 16.1709 12.1835 17.0822C13.0962 17.9934 14.5594 17.9934 15.4721 17.0822C16.3848 16.1709 16.3848 14.71 15.4721 13.7988C14.5594 12.902 13.0817 12.902 12.1835 13.7988Z" fill="#F8AC18" />
    <path d="M10.5321 18.7166C9.61938 17.8054 8.15618 17.8054 7.2435 18.7166L0.680852 25.2834C-0.231834 26.1946 -0.231834 27.6555 0.680852 28.5667C1.59354 29.478 3.05673 29.478 3.96942 28.5667L10.5466 22C11.4448 21.1032 11.4448 19.6279 10.5321 18.7166Z" fill="#F8AC18" />
    <path d="M59.1072 10.4576C59.1072 11.7449 60.1503 12.7718 61.4251 12.7718C62.7145 12.7718 63.7431 11.7304 63.7431 10.4576C63.7431 9.17027 62.7 8.14331 61.4251 8.14331C60.1503 8.14331 59.1072 9.18473 59.1072 10.4576Z" fill="#F8AC18" />
    <path d="M61.4251 14.7534C60.1358 14.7534 59.1072 15.7948 59.1072 17.0677V26.9323C59.1072 28.2196 60.1503 29.2465 61.4251 29.2465C62.7145 29.2465 63.7431 28.2051 63.7431 26.9323V17.0677C63.7575 15.7948 62.7145 14.7534 61.4251 14.7534Z" fill="#F8AC18" />
    <path d="M18.7463 26.9177C17.8336 27.8289 17.8336 29.2898 18.7463 30.2011C19.659 31.1123 21.1222 31.1123 22.0349 30.2011C22.9475 29.2898 22.9475 27.8289 22.0349 26.9177C21.1367 26.0209 19.659 26.0209 18.7463 26.9177Z" fill="#F8AC18" />
    <path d="M23.6862 25.2833C24.5989 26.1945 26.0621 26.1945 26.9748 25.2833L33.5519 18.7166C34.4646 17.8053 34.4646 16.3444 33.5519 15.4332C32.6392 14.5219 31.176 14.5219 30.2634 15.4332L23.6862 21.9999C22.7735 22.9112 22.7735 24.372 23.6862 25.2833Z" fill="#F8AC18" />
    <path d="M18.7463 3.96314C19.659 3.05189 19.659 1.59101 18.7463 0.67977C17.8336 -0.231473 16.3704 -0.231473 15.4577 0.67977C14.545 1.59101 14.545 3.05189 15.4577 3.96314C16.3704 4.85991 17.8481 4.85991 18.7463 3.96314Z" fill="#F8AC18" />
    <path d="M3.96942 18.7166L13.8206 8.88098C14.7333 7.96974 14.7333 6.50886 13.8206 5.59762C12.9079 4.68637 11.4447 4.68637 10.5321 5.59762L0.680852 15.4332C-0.231834 16.3445 -0.231834 17.8054 0.680852 18.7166C1.59354 19.6279 3.05673 19.6279 3.96942 18.7166Z" fill="#F8AC18" />
    <path d="M15.4721 40.0512C14.5594 40.9625 14.5594 42.4233 15.4721 43.3346C16.3848 44.2458 17.848 44.2458 18.7607 43.3346C19.6734 42.4233 19.6734 40.9625 18.7607 40.0512C17.848 39.14 16.3703 39.14 15.4721 40.0512Z" fill="#F8AC18" />
    <path d="M33.5374 25.2833C32.6248 24.372 31.1616 24.372 30.2489 25.2833L20.3976 35.1189C19.485 36.0302 19.485 37.491 20.3976 38.4023C21.3103 39.3135 22.7735 39.3135 23.6862 38.4023L33.5374 28.5666C34.4501 27.6554 34.4501 26.1945 33.5374 25.2833Z" fill="#F8AC18" />
    <path d="M23.6864 15.4477C22.7737 14.5365 21.3105 14.5365 20.3978 15.4477L10.5466 25.2833C9.63389 26.1946 9.63389 27.6555 10.5466 28.5667C11.4593 29.478 12.9225 29.478 13.8351 28.5667L23.6864 18.7311C24.5846 17.8198 24.5846 16.3445 23.6864 15.4477Z" fill="#F8AC18" />
  </svg>
</template>

<script>
export default {
  name: 'LogoDivvy',
}
</script>
